@use 'styles/theme/theme' as theme;

.representationTerritories {
  display: flex;
  align-items: flex-start;
  grid-gap: 8px;
  flex-wrap: wrap;
  margin-bottom: 8px;
  &:last-child {
    margin-bottom: 0;
  }
  &_group {
    display: flex;
    flex-wrap: wrap;
    gap: 4px 0;
  }
  &_displayMode {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 8px;
  }
}

.territory {
  &_type {
    padding-right: 4px;
  }
  &_country {
    padding: 2px 6px;
    background: theme.$grey9;
    margin: 0 2px;
  }
}
