@use 'styles/theme/theme' as theme;

.noAccessModal {
  &__container {
    width: 463px !important;
  }
  &__content {
    padding: 16px;
  }
  &__footer {
    padding: 16px;
    border-top: 1px solid theme.$borderGrey;
    justify-content: space-between;
    display: flex;
  }
  &__primaryCTA {
    width: 100%;
    margin-right: 16px;
    white-space: normal;
  }
  &__secondaryCTA {
    width: 100%;
  }
}

.loader {
  display: flex;
  justify-content: center;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}
