@use 'styles/theme/theme' as theme;

.contactsDetailsModal {
  width: 463px !important;
}

.contactsDetails {
  padding: 16px 16px 24px 16px;
}

.contactGrid {
  display: grid;
  grid-template-columns: 0.7fr 1fr;
  margin-bottom: 16px;
}

.contactSection {
  display: flex;
  flex-direction: column;
  row-gap: 8px;
}

.contactLink {
  display: flex;
  justify-content: space-between;
  &:hover {
    .openNewIcon {
      fill: theme.$blue;
    }
    span {
      color: theme.$blue;
      text-decoration: underline;
    }
  }
}

.openNewIcon {
  fill: theme.$grey8;
}

.contactValue {
  word-break: break-all;
  overflow-wrap: break-word;
  white-space: normal;
  flex: 1;
}
