@use 'styles/theme/theme' as theme;

.scrollableContent {
  gap: 16px;
  display: flex;
  align-items: center;
}

@media screen and (max-width: 600px) {
  .scrollableContent {
    gap: 8px;
    padding-right: 16px;
  }
}

.viewAllCard {
  height: 86px;
  max-height: 86px;
  padding: 12px 14px;
  justify-content: center;
}
