@import './colors';
@import './typography';

$border-radius-none: 0;
$border-radius-extra_small: 4px;
$border-radius-small: 8px;
$border-radius-medium: 16px;
$border-radius-large: 64px;
$border-radius-full: 50%;
$desktop-max-width: 1280px;
$tablet-min-width: 1024px;
$phone-min-width: 375px;

$desktop-header-full-height: 97px;
$desktop-header-navbar-height: 24px;

@mixin container($maxWidth: $desktop-max-width, $tabPadding: 16px, $mobilePadding: 16px) {
  width: 100%;
  max-width: $desktop-max-width;
  margin: auto;
  padding: 0;

  @media only screen and (max-width: $desktop-max-width) {
    padding-left: $tabPadding;
    padding-right: $tabPadding;
  }

  @media only screen and (max-width: $tablet-min-width) {
    padding-left: $mobilePadding;
    padding-right: $mobilePadding;
  }
}

@mixin bulletStyle {
  height: 4px;
  min-height: 4px;
  max-height: 4px;
  width: 4px;
  min-width: 4px;
  min-height: 4px;
  background-color: $secondaryGrey;
  border-radius: 50%;
  display: inline-block;
}
@mixin noSelection() {
  -webkit-touch-callout: none;
  /* iOS Safari */
  -webkit-user-select: none;
  /* Safari */
  -khtml-user-select: none;
  /* Konqueror HTML */
  -moz-user-select: none;
  /* Old versions of Firefox */
  -ms-user-select: none;
  /* Internet Explorer/Edge */
  user-select: none;
  /* Non-prefixed version, currently supported by Chrome, Edge, Opera and Firefox */
}

@mixin truncateText {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
