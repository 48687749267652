@use 'styles/theme/theme' as theme;

.root {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  row-gap: 8px;
  padding: 6px 8px;
  border-radius: 8px;
  border: 1px solid var(--current-theme-card-border-color);
  background: var(--current-theme-card-background-color);
  text-decoration: none !important;
  &:hover {
    .viewAll {
      color: var(--current-theme-cta-text-color);
    }
    .icon {
      fill: var(--current-theme-cta-text-color) !important;
    }
  }
  &__count {
    color: var(--current-theme-count-text-color);
  }
  &__titleSection {
    display: flex;
    justify-content: space-between;
    align-items: center;
    column-gap: 16px;
  }
  .icon {
    fill: var(--current-theme-icon-secondary-color) !important;
  }
}
