@use 'styles/theme/theme' as theme;

.linkWrapper {
  width: 100%;
  display: block;
}

.bioLanguages {
  margin-top: 4px;
  color: var(--current-theme-primary-text-color);
}

.readMoreLink {
  color: var(--current-theme-cta-text-color);
}

.content {
  position: relative;
}

.excerpt {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: normal;
  line-clamp: 13;
  -webkit-line-clamp: 13;
  color: var(--current-theme-primary-text-color);
  @media screen and (max-width: 1280px) {
    line-clamp: 2;
    -webkit-line-clamp: 2;
  }
}
