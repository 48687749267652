@use 'styles/theme/theme' as theme;

.agencyDetails {
  padding: 16px 16px 24px 16px;
  & + & {
    border-top: 1px solid theme.$secondaryGrey;
  } 
}

.agencyDetailsSection {
  padding: 14px 0 0;
  &:first-child {
    padding-top: 0;
  }
}

.agencySectionTitle {
  display: block;
  margin-bottom: 8px;
}

.agencyGrid {
  display: grid;
  grid-template-columns: 1fr 1fr;
  margin-bottom: 10px;
}

.agencyAgreements {
  &+& {
    margin-top: 10px;
  }
}

.agencyProfession {
  display: grid;
  grid-template-columns: 1fr 1fr;
  column-gap: 8px;
  margin-bottom: 10px;
}

.notApproved {
  color: theme.$red;
  i {
    display: flex;
    align-items: center;
    column-gap: 3px;
  }
}

.agencyName {
  display: flex;
  flex-direction: column;
  row-gap: 3px;
}

.openNewIcon {
  fill: theme.$grey8;
  margin-left: 6px;
}

.agencyLink {
  display: flex;
  justify-content: flex-start;
  white-space: pre-wrap;
  &:hover {
    .openNewIcon {
      fill: theme.$blue;
    }
    span {
      color: theme.$blue;
      text-decoration: underline;
    }
  }
}

.agreementDetails {
  display: flex;
  flex-wrap: wrap;
  column-gap: 8px;
  row-gap: 20px;
}

.agreementGrid {
  margin-bottom: 20px;
}

.agreementName {
  padding: 3px;
  border-radius: 32px;
  background-color: theme.$whiteSmoke;
  text-transform: uppercase;
}

.contactLink {
  display: inline;
  &:hover {
    .openNewIcon {
      fill: theme.$blue;
    }
    span {
      color: theme.$blue;
      text-decoration: underline;
    }
  }
}

.contactValue {
  word-break: break-all;
  overflow-wrap: break-word;
  white-space: normal;
  flex: 1;
}

.agencyContacts {
  display: flex;
  flex-direction: column;
  row-gap: 7px;
}

.representationDetail {
  display: grid;
  grid-template-columns: 1fr 1fr;
  column-gap: 8px;
}