@use 'styles/theme/theme' as theme;

.root {
  flex-shrink: 0;
  border: 1px solid var(--current-theme-card-border-color);
  width: 416px;
  max-width: 416px;
  max-height: 86px;
  height: 86px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 12px 0px;
  border-radius: theme.$border-radius-small;
  background: var(--current-theme-card-background-color);

  &__withProduction {
    height: 134px;
    max-height: 134px;
    padding: 12px 0px 12px;
  }
}

.reviewCard {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 0 14px;
  height: 100%;
  &__content {
    display: flex;
    height: 100%;
    max-height: 60px;
    height: 60px;
    justify-content: space-between;
    flex-direction: column;
   
    &_withProdInfo {
      margin-bottom: 12px;
    }
  }
}

.productionContainer {
  display: flex;
  flex-direction: column;
  row-gap: 4px;
  padding: 12px 16px 0px;
  border-top: 1px solid theme.$borderGrey;
  background-color: theme.$white;
  min-height: 44px;
  &__info {
    display: flex;
    gap: 4px;
    & > span {
      max-width: max-content;
    }
  }
}

.prodInfoCard {
  height: 100%;
  &__footer {
    width: calc(100% + 28px);
    margin-left: -14px;
    border-top: 1px solid;
    margin-top: 12px;
    padding-top: 12px;
    padding-left: 16px;
    padding-right: 16px;
    & > div {
      background-color: theme.$white;
      padding: 0;
    }
  }
}

.review {
  position: relative;
  cursor: pointer;
  border-left: 2px solid theme.$yellow2;
  padding-left: 8px;
  display: flex;
  & > div {
    padding-left: 21px;
  }
  &_content {
    line-height: normal;
    white-space: normal;
    &:after,
    &:before {
      display: none;
    }
  }

}

.root .readMore {
  max-height: 19px;
  color: var(--current-theme-cta-text-color);
  background: var(--current-theme-card-background-color);
  @media screen and (max-width: 600px) {
    right: 6px;
  }
}

.quotes {
  &_open {
    height: 10px;
    width: 13px;
    position: absolute;
  }
  &_close {
    height: 18px;
    width: 24px;
  }
}

.metaInfo {
  display: flex;
  align-items: center;
  justify-content: space-between;

  &_basic {
    display: flex;
    align-items: center;
    flex-wrap: nowrap;
    gap: 4px;
    max-width: 235px;
  }

  &_link {
    svg {
      fill: theme.$tertiaryGrey !important;
    }
  }

  &_date {
    padding-left: 29px;
    line-height: theme.$line-height-14;
  }

  &_author {
    text-align: end;
  }
}

.credits {
  display: flex;
  white-space: nowrap;
  gap: 4px;
  & > span {
    max-width: max-content;
  }
}

@media screen and (max-width: 1020px) {
  .root {
    max-width: 315px;
    width: 315px;
    flex-shrink: 0;
    padding: 8px 0px;
    height: 78px;
    max-height: 78px;
    &__withProduction {
      height: 130px;
      max-height: 130px;
    }
  }

  .reviewCard {
    &__content {
      &_withProdInfo {
        max-height: 100%;
        height: 100%;
        margin-bottom: 12px;
      }
    }
  }

  .productionContainer {
    padding-top: 8px;
  }

  .metaInfo {
    &_date {
      padding-left: 0;
    }
    &_basic {
      max-width: 171px;
      width: 171px;
      justify-content: flex-end;
    }
  }

  .review {
    &_content {
      line-height: normal;
      font-size: theme.$font-size-14;
      white-space: normal;
    }
  }
}