@use 'styles/theme/theme' as theme;

.header {
  display: grid;
  align-items: flex-start;
  padding: 12px 9px;
  grid-template-columns: 0.33fr 0.33fr 0.33fr;
  border-radius: 2px;
  background: theme.$whiteSmoke;
}

.composer {
  display: flex;
  flex-direction: column;
  row-gap: 14px;
  padding: 8px 12px 16px;
  border-bottom: 1px solid theme.$borderGrey;
}

.work {
  display: grid;
  grid-template-columns: 0.33fr 0.33fr 0.33fr;
}

.workName {
  margin-left: 15px;
}

.role {
  display: flex;
  flex-direction: column;
  row-gap: 16px;
}

.roleName {
  display: flex;
  align-items: center;
  gap: 8px;
}

.status {
  padding: 1px 4px;
  border-radius: 10px;
  background: theme.$whiteSmoke;
  width: max-content;
  height: max-content;
  text-transform: capitalize;
}

.overviewComposer {
  display: flex;
  flex-direction: column;
  row-gap: 5px;
  padding: 14px 0;
  border-bottom: 1px solid theme.$borderGrey;

  &:first-child {
    padding-top: 4px;
  }
}

.overviewWork {
  display: flex;
  flex-direction: column;
  margin-left: 15px;
  row-gap: 5px;
  margin-bottom: 14px;

  &:last-child {
    margin-bottom: 0;
  }
}

.overviewRoleInfo {
  display: flex;
  align-items: center;
  column-gap: 6px;
}

.overviewRoleSection {
  display: flex;
  flex-direction: column;
  row-gap: 5px;
}

.overviewRole {
  display: flex;
  flex-direction: column;
  row-gap: 5px;
}

.overviewRoleName {
  display: flex;
  align-items: center;
  gap: 6px;
}

.inlineContainer {
  display: flex;
  gap: 8px;
  .repertoireCard {
    display: flex;
    justify-content: space-between;
    width: 230px;
    padding: 6px 8px;
    border-radius: 8px;
    border: 1px solid var(--current-theme-card-border-color);
    background: var(--current-theme-card-background-color);
    &__content {
      display: flex;
      flex-direction: column;
      row-gap: 8px;
      width: 165px;
    }
    &__info {
      display: flex;
      flex-direction: column;
    }
    &__icon {
      margin-right: 4px;
      fill: theme.$grey15;
    }
    .prepared,
    .announced {
      color: theme.$green1;
    }
    .unPrepared {
      color: theme.$secondaryGrey;
    }
    .retire,
    .past {
      color: theme.$orange4;
    }
  }
}
