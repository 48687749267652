@use 'styles/theme/theme' as theme;

.root {
  display: flex;
  flex-wrap: wrap;
  grid-gap: 14px 8px;

  @media screen and (max-width: 1280px) {
    flex-wrap: nowrap;
    & > a {
      width: auto;
    }
  }
}