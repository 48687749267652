@use 'styles/theme/theme' as theme;

.contactHeader {
  display: flex;
  align-items: baseline;
  justify-content: center;
  height: 44px;
  padding: 4px 0px 4px 16px;
  border: 1px solid theme.$whiteSmoke;
  border-bottom: none;
  border-radius: 8px 8px 0px 0px;
  background-color: theme.$white;
}

.contactHeaderTitle {
  font-size: 14px;
}

.contactSectionHeader {
  margin-bottom: 0;
  justify-content: space-between;
  width: 100%;
  padding-right: 16px;
  border-bottom: 0;
  padding-bottom: 0;
}

.leftBoxIcon {
  @media only screen and (max-width: 600px) {
    &::before {
      display: none;
    }
  }
}

.contactSection {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding-bottom: 12px;
  border-radius: 0px 0px 8px 8px;
  border: 1px solid theme.$whiteSmoke;
}

.contactRow {
  display: flex;
  justify-content: space-between;
  column-gap: 6px;
  width: 100%;
}

.contactLeftSection {
  display: flex;
  align-items: center;
  column-gap: 6px;
}

.contactItem {
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding: 16px;
  cursor: pointer;

  &:hover {
    background-color: theme.$blueHover;
    border-radius: 0;
    border-left: 2px solid theme.$blue;
    .rightIcon {
      fill: theme.$blue;
    }
    .ctaTitle {
      color: theme.$blue;
    }
    .contactName,
    .loginToView {
      color: theme.$blue;
    }
    .contactIcon {
      display: none;
    }
    .contactHoverIcon {
      display: block;
    }
  }
}

.contactHoverIcon {
  display: none;
}

.ctaSection {
  display: flex;
  align-items: center;
  column-gap: 8px;
}

.rightIcon {
  fill: theme.$grey8;
}

.contactName {
  &:first-letter {
    text-transform: uppercase;
  }
}
