@use 'styles/theme/theme' as theme;

.inline {
  margin-right: 8px;
}

.socialLinksHeader {
  display: flex;
  align-items: center;
  height: 44px;
  padding: 4px 0px 4px 16px;
  border: 1px solid theme.$whiteSmoke;
  border-bottom: none;
  border-radius: 8px 8px 0px 0px;
  background-color: theme.$white;
  justify-content: space-between;
  width: 100%;
  padding-right: 16px;
}

.socialLinksHeaderTitle {
  font-size: 14px;
}

.socialLinksSectionHeader {
  margin-bottom: 0;
  border-bottom: 0;
  padding-bottom: 0;
}

.leftBoxIcon {
  @media only screen and (max-width: 600px) {
    &::before {
      display: none;
    }
  }
}

.socialLinksContent {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding-bottom: 12px;
  border-radius: 0px 0px 8px 8px;
  border: 1px solid theme.$whiteSmoke;
}

.socialLink {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 16px;

  &:hover {
    background-color: theme.$blueHover;
    border-radius: 0;
    border-left: 2px solid theme.$blue;
    .openNewIcon {
      fill: theme.$blue;
    }
    .linkName {
      color: theme.$blue;
    }
    .socialIcon {
      display: none;
    }
    .socialHoverIcon {
      display: block;
    }
  }
}

.socialHoverIcon {
  display: none;
}

.socialLinkInfo {
  display: flex;
  align-items: center;
  column-gap: 8px;
}

.linkName {
  &:first-letter {
    text-transform: uppercase;
  }
}

.openNewIcon {
  fill: theme.$grey8;
}

.scrollLeftIndicator,
.scrollRightIndicator {
  height: 32px;
  padding: 10px 10px 10px 8px;
  box-shadow: 2px 0 10px 0 rgba(0, 0, 0, 0.3);
  border: transparent;
  cursor: pointer;

  svg {
    width: 12px !important;
    height: 12px !important;
  }
}

.contactList {
  display: flex;

  .divider {
    margin: auto;
    margin-left: 4px;
    margin-right: 12px;
    height: 30px;
    border-right: 1px solid theme.$tertiaryGrey;
  }
}

.contactOverflowContainer {
  display: flex;
  align-items: center;
  > div {
    display: inline-flex;
  }
  &__link {
    text-decoration: none !important;
    &:hover {
      .contactOverflowContainer__count {
        color: theme.$yellow2;
      }
    }
  }
  &__count {
    padding: 10px;
    border-radius: 50%;
    background-color: theme.$blackGrey4;
  }
}
