@import 'styles/theme/theme';

.modal {
  display: flex;
  &__container {
    max-height: 520px !important;
  }
}

.reviewModal {
  box-sizing: border-box;
  padding: 16px;
  border-top: 1px solid $tertiaryGrey;

  &_description {
    margin-top: 8px;
    word-break: break-all;
  }
}

.sticky {
  position: sticky;
  top: 0;
  z-index: 1;
}

.productionInfo {
  border-top: 0;
  padding: 12px 16px;
  background-color: $whiteSmoke;
  border-bottom: 1px solid $tertiaryGrey;
}

@media screen and (max-width: 600px) {
  .modal {
    &__container {
      width: 100% !important;
      max-height: 90% !important;
      height: 70% !important;
    }
  }

  .drawerPaper {
    max-height: 90% !important;
    height: 70% !important;
  }

  .reviewModal {
    padding: 12px 16px;
    border-top: none;
  }
}
