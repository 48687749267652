@use 'styles/theme/theme' as theme;
.sectionWrapper {
  &__title {
    margin-bottom: 12px;
    white-space: pre-line;
    text-align: start;
    @media screen and (max-width: 1280px) {
      color: theme.$white;
    }
  }
}

@media screen and (min-width: 1280px) {
  .sectionWrapper {
    &__premiumSection {
      margin-right: -19px;
      width: calc(100% + 19px) !important;
    }
  }
}


@media screen and (max-width: 1280px) {
  .sectionWrapper {
    margin-bottom: 0px;
  }
}
