@use 'styles/theme/theme' as theme;

.notApproved {
  display: inline-flex;
  align-items: center;
  gap: 2px;
  color: theme.$red;
}

.agencyName {
  white-space: break-spaces;
  text-align: left;
}

.agencyLink {
  display: flex;
  align-items: center;
  justify-content: left;
  width: 100%;
  cursor: pointer;
  &:hover {
    .rightIcon {
      fill: var(--current-theme-icon-primary-color);
    }
    .notApproved {
      color: theme.$red;
    }
    span {
      color: var(--current-theme-cta-text-color);
      text-decoration: underline;
    }
  }
}

.agencyItem {
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding: 16px;
  cursor: pointer;

  &:hover {
    background-color: theme.$blueHover;
    border-radius: 0;
    border-left: 2px solid theme.$blue;
    .rightIcon {
      fill: var(--current-theme-icon-primary-color);
    }
    .ctaTitle {
      color: theme.$blue;
    }
    .agencyName,
    .agreement,
    .loginToView {
      color: theme.$blue;
    }
    .agencyIcon {
      display: none;
    }
    .agencyHoverIcon {
      display: block;
    }
  }
}

.agencyHoverIcon {
  display: none;
}

.agencyCardInfo {
  display: flex;
  align-items: center;
  column-gap: 6px;

  @media screen and (max-width: 1200px) {
    flex-direction: column;
    align-items: flex-start;
    gap: 6px;
  }
}

.agencyOverviewInfo {
  flex-wrap: wrap;
  flex-direction: row;
  align-items: center;
}

.ctaSection {
  display: flex;
  align-items: center;
  column-gap: 8px;

  @media screen and (max-width: 1200px) {
    margin-top: 10px;
  }
}

.ctaSectionOverview {
  margin-top: 0;
}

.rightIcon {
  fill: var(--current-theme-icon-secondary-color);
}

.agencyRow {
  display: flex;
  align-items: center;
  column-gap: 6px;
  width: 100%;
}

.agencySection {
  @media screen and (min-width: 1200px) {
    display: flex;
    justify-content: space-between;
    width: 100%;
  }
}

.agencySectionOverview {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}
