@use 'styles/theme/theme' as theme;

.agencies {
  display: flex;
  flex-direction: column;
  row-gap: 14px;
}

.notApproved {
  display: inline-flex;
  align-items: center;
  gap: 2px;
  color: theme.$red;
}

.agencyInfo {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  column-gap: 6px;
}

.agencyHeader {
  display: flex;
  align-items: center;
  height: 44px;
  padding: 4px 0px 4px 16px;
  border: 1px solid theme.$whiteSmoke;
  border-bottom: none;
  border-radius: 8px 8px 0px 0px;
  background-color: theme.$white;
}

.agencyHeaderTitle {
  font-size: 14px;
}

.agencySectionHeader {
  margin-bottom: 0 !important;
  justify-content: space-between;
  width: 100%;
  padding-right: 16px;
  border-bottom: 0;
  padding-bottom: 0;
}

.leftBoxIcon {
  @media only screen and (max-width: 600px) {
    &::before {
      display: none !important;
    }
  }
}

.agencyContent {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding-bottom: 12px;
  border-radius: 0px 0px 8px 8px;
  border: 1px solid theme.$whiteSmoke;
}
