.infoAndCollaborators {
  display: grid;
  gap: 20px;
  &__left {
    display: flex;
    flex-direction: column;
    gap: 32px 20px;
  }

  &__columns {
    &_0 {
      grid-template-columns: 100%;
      @media screen and (min-width: 1280px) {
        .infoAndCollaborators__left {
          display: grid;
          grid-template-columns: 1fr 1fr;
          gap: 20px;
        }
      }
    }
    &_1 {
      grid-template-columns: auto 415px;
    }
    &_2 {
      grid-template-columns: auto repeat(2, 270px);
    }
    &_3 {
      grid-template-columns: auto repeat(3, 270px);
    }
  }
}

@media screen and (max-width: 1280px) {
  .infoAndCollaborators {
    display: flex;
    flex-direction: column;
    gap: 24px;

    &__left {
      gap: 24px;
    }
  }
}