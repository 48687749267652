@import 'styles/theme/theme';

.truncate {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.root {
  overflow: hidden;
  position: relative;
}

.anchor {
  position: absolute;
  bottom: 0;
  right: 0;
  max-height: 22px;
  background: $backgroundGrey;

  &::before {
    content: "... ";
  }

  &.isEmbedded {
    position: static;
    background: transparent;

    &::before {
      content: "";
    }
  }
}

.readMore {
  display: inline-flex;
  align-items: center;
  color: var(--current-theme-cta-text-color);
  cursor: pointer;
  svg {
    display: flex;
    align-self: center;
  }
}