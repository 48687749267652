@use 'styles/theme/theme' as theme;

.contactSection {
  display: flex;
  flex-direction: column;
  row-gap: 16px;
  .contactLink {
    display: flex;
    align-items: center;
    justify-content: left;
    width: 100%;
    .rightArrow {
      position: absolute;
      right: 0;
      fill: theme.$grey8;
    }
    &:hover {
      .rightArrow {
        fill: theme.$blue;
      }
      span {
        color: theme.$blue;
        text-decoration: underline;
      }
    }
  }
  .contactSubtitle {
    margin-left: 5px;
  }
}

.contacts {
  display: flex;
  flex-direction: column;
  row-gap: 16px;
}

.contactSection {
  display: flex;
  flex-direction: column;
  gap: 16px;
}
